<template>
  <v-sheet max-width="800px" class="d-flex justify-center mx-auto flex-wrap" color="transparent">
    <template v-for="item in menuItems">
        <v-sheet 
          v-if="hasRole(item.role)"
          :key="item.label" 
          width="150px" 
          min-width="150px" 
          class="menu-btn text-center ma-4 pa-2" 
          elevation="3" 
          rounded="lg" 
          @click.stop="navigateTo(item.routeName)" 
        >
          <v-icon class="button-icon">{{item.icon}}</v-icon>
          <div>{{item.label}}</div>
        </v-sheet>
    </template>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  data: () => ({
    menuItems: [
      {label: 'Budget', icon: 'mdi-currency-usd', routeName: 'Budget', role: "BudgetUser"},
      {label: 'Fuel Tracking', icon: 'mdi-fuel', routeName: 'Select Vehicle', role: "FuelTrackingUser"},
      {label: 'Admin', icon: 'mdi-shield-key', routeName: 'Admin', role: "Admin"},
    ],
  }),
  computed: {
    ...mapGetters("AuthModule", { 
      hasRole: "hasRole"
    }),
  },
  methods: {
    navigateTo(routeName) {
      this.$router.push({name: routeName});
    }
  }
};
</script>

<style scoped>
  .menu-btn:hover {
    cursor: pointer;
  }
  .menu-btn > div {
    font-size: 18px;
    text-align: center;
  }
  .v-icon.button-icon {
    font-size: 70px;
    height: 100px;
    color: #555;
  }
</style>
